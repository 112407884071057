module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":300,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["ja","en"],"defaultLanguage":"ja","fallbackLanguage":"en","generateDefaultLanguagePage":true,"redirect":false,"i18nextOptions":{"fallbackLng":"ja","interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/guide/:slag","getLanguageFromPath":true,"excludeLanguages":["en","ja"]},{"matchPath":"/:lang?/guide/general/:slag","getLanguageFromPath":true,"excludeLanguages":["en","ja"]},{"matchPath":"/:lang?/guide/business/:slag","getLanguageFromPath":true,"excludeLanguages":["en","ja"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icons/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1e72edcd0a6bb50a8d63f46e38d5e3f1"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
